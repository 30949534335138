import React, { Component } from 'react';

export default class Content extends Component {
    render() {
        return(
            <main id="PAGES_CONTAINER" class="PAGES_CONTAINER" tabIndex="-1" data-main-content="true">
                <div id="SITE_PAGES" data-page-transition="OutIn" class="JshATs SITE_PAGES">
                    <div id="SITE_PAGES_TRANSITION_GROUP" class="fcNEqv">
                        <div id="c1lo9" class="dBAkHi c1lo9">
                            <div class="HT5ybB">
                                <div id="Containerc1lo9" class="Containerc1lo9 SPY_vo">
                                    <div data-mesh-id="Containerc1lo9inlineContent" data-testid="inline-content"
                                         class="">
                                        <div data-mesh-id="Containerc1lo9inlineContent-gridContainer"
                                             data-testid="mesh-container-content">
                                            <section id="comp-l6y7k0op" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0op wixui-section">
                                                <div id="bgLayers_comp-l6y7k0op" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0op" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0opinlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0opinlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmmx3w7"
                                                                 class="comp-kzmmx3w7 CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmmx3w7" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmmx3w7"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmmx3yl"
                                                                     class="comp-kzmmx3yl YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmmx3yl"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmmx3yl"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmmx3ylinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmmx3ylinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmn44fg"
                                                                                 class="MazNVa comp-kzmn44fg wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmn44fg"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmn44fg&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:false,&quot;imageData&quot;:{&quot;width&quot;:4961,&quot;height&quot;:3508,&quot;uri&quot;:&quot;888d49_8405652d3dfc4997882412f8a6bfa3ef~mv2.png&quot;,&quot;name&quot;:&quot;cabin-linework1.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="">
                                                                                        <img
                                                                                        src="https://static.wixstatic.com/media/888d49_8405652d3dfc4997882412f8a6bfa3ef~mv2.png/v1/fill/w_1922,h_1360,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/cabin-linework1.png"
                                                                                        alt="cabin-linework1.png"
                                                                                        style={{objectFit : 'cover'}}
                                                                                        srcSet="https://static.wixstatic.com/media/888d49_8405652d3dfc4997882412f8a6bfa3ef~mv2.png/v1/fill/w_1922,h_1360,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/cabin-linework1.png 1x, https://static.wixstatic.com/media/888d49_8405652d3dfc4997882412f8a6bfa3ef~mv2.png/v1/fill/w_1922,h_1360,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/cabin-linework1.png 2x"
                                                                                        fetchpriority="high"/>
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmucnjz"
                                                                                 class="KcpHeO tz5f0K comp-kzmucnjz wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Final Painting</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmnmqbi"
                                                                                 class="MazNVa comp-kzmnmqbi wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmnmqbi"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmnmqbi&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:false,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1358,&quot;uri&quot;:&quot;888d49_360967ab24374d9a89b344cfefe57057~mv2.jpg&quot;,&quot;name&quot;:&quot;callouts-copy.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src=""><img
                                                                                        src="https://static.wixstatic.com/media/888d49_360967ab24374d9a89b344cfefe57057~mv2.jpg/v1/fill/w_961,h_680,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/callouts-copy.jpg"
                                                                                        alt="callouts-copy.jpg"
                                                                                        style={{ objectFit : 'cover' }}
                                                                                        srcSet="https://static.wixstatic.com/media/888d49_360967ab24374d9a89b344cfefe57057~mv2.jpg/v1/fill/w_961,h_680,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/callouts-copy.jpg 1x, https://static.wixstatic.com/media/888d49_360967ab24374d9a89b344cfefe57057~mv2.jpg/v1/fill/w_1919,h_1358,al_c,q_90,enc_auto/callouts-copy.jpg 2x"
                                                                                        fetchpriority="high"/>
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmudzvc"
                                                                                 class="KcpHeO tz5f0K comp-kzmudzvc wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Final Painting with callouts</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmnxrn1"
                                                                                 class="MazNVa comp-kzmnxrn1 wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmnxrn1"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmnxrn1&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1358,&quot;uri&quot;:&quot;888d49_002f3ba754a9441bb2b8c4e46c326169~mv2.jpg&quot;,&quot;name&quot;:&quot;cabin-lines.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_002f3ba754a9441bb2b8c4e46c326169~mv2.jpg/v1/fill/w_1919,h_1358,al_c,q_90,enc_auto/cabin-lines.jpg"
                                                                                            alt="cabin-lines.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmuel46"
                                                                                 class="KcpHeO tz5f0K comp-kzmuel46 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Linework</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmo15sq"
                                                                                 class="MazNVa comp-kzmo15sq wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmo15sq"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmo15sq&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:679,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1357,&quot;uri&quot;:&quot;888d49_3f12dd2200b342a499067d8a7e1b508b~mv2.jpg&quot;,&quot;name&quot;:&quot;cabin-render.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_3f12dd2200b342a499067d8a7e1b508b~mv2.jpg/v1/fill/w_1920,h_1357,al_c,q_90,enc_auto/cabin-render.jpg"
                                                                                            alt="cabin-render.jpg"
                                                                                            style={{objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmufql6"
                                                                                 class="KcpHeO tz5f0K comp-kzmufql6 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">3D Model</span>
                                                                            </p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmmxnbk"
                                                                     class="comp-kzmmxnbk YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmmxnbk"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmmxnbk"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmmxnbkinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmmxnbkinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmn1jue"
                                                                                 class="KcpHeO tz5f0K comp-kzmn1jue wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Explorer Submarine</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmro5vr"
                                                                                 class="KcpHeO tz5f0K comp-kzmro5vr wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Set Design</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmni1d9"
                                                                                 class="KcpHeO tz5f0K comp-kzmni1d9 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >Set
                                                                                designed a British explorer&rsquo;s
                                                                                living quarters in a submarine
                                                                                during 1914 WW1. The captain is a
                                                                                mountaineer on an Arctic expedition
                                                                                to explore and trek mountains 🏔.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0op1" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0op1 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0op1" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0op1" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0op1inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0op1inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmo4qvl"
                                                                 class="comp-kzmo4qvl CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmo4qvl" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmo4qvl"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmo4qwk"
                                                                     class="comp-kzmo4qwk YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmo4qwk"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmo4qwk"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmo4qwkinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmo4qwkinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmo4qwp"
                                                                                 class="MazNVa comp-kzmo4qwp wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmo4qwp"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmo4qwp&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1357,&quot;uri&quot;:&quot;888d49_427e8a61087a4e728ccc3f12fdeaf27d~mv2.jpg&quot;,&quot;name&quot;:&quot;throne-render.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_427e8a61087a4e728ccc3f12fdeaf27d~mv2.jpg/v1/fill/w_1918,h_1357,al_c,q_90,enc_auto/throne-render.jpg"
                                                                                            alt="throne-render.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmutpby"
                                                                                 class="KcpHeO tz5f0K comp-kzmutpby wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Final Painting</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmo4qwu"
                                                                                 class="MazNVa comp-kzmo4qwu wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmo4qwu"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmo4qwu&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:4961,&quot;height&quot;:3508,&quot;uri&quot;:&quot;888d49_e94128ff0d0645cab7c19cf39ecd7333~mv2.png&quot;,&quot;name&quot;:&quot;throne-linework1 copy.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_e94128ff0d0645cab7c19cf39ecd7333~mv2.png/v1/fill/w_1922,h_1360,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/throne-linework1%20copy.png"
                                                                                            alt="throne-linework1 copy.png"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmuuzyx"
                                                                                 class="KcpHeO tz5f0K comp-kzmuuzyx wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Linework</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmo4qwy"
                                                                                 class="MazNVa comp-kzmo4qwy wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmo4qwy"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmo4qwy&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:4961,&quot;height&quot;:3508,&quot;uri&quot;:&quot;888d49_154b6e7ec304431aacb9ab602eb81d0f~mv2.jpg&quot;,&quot;name&quot;:&quot;throne-render1 copy.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_154b6e7ec304431aacb9ab602eb81d0f~mv2.jpg/v1/fill/w_1922,h_1360,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/throne-render1%20copy.jpg"
                                                                                            alt="throne-render1 copy.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmuwnp8"
                                                                                 class="KcpHeO tz5f0K comp-kzmuwnp8 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">3D Model</span>
                                                                            </p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmo4qx6"
                                                                     class="comp-kzmo4qx6 YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmo4qx6"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmo4qx6"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmo4qx6inlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmo4qx6inlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmo4qxb"
                                                                                 class="KcpHeO tz5f0K comp-kzmo4qxb wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Viking Throne Room</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmrp9qd"
                                                                                 class="KcpHeO tz5f0K comp-kzmrp9qd wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Set Design</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmo4qxf"
                                                                                 class="KcpHeO tz5f0K comp-kzmo4qxf wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >Set
                                                                                dressed a dragon-themed throne room
                                                                                based on Nordic stave churches.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0oq1" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0oq1 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0oq1" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0oq1" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0oq1inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0oq1inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmu3c2t"
                                                                 class="comp-kzmu3c2t CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmu3c2t" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmu3c2t"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmu3c47"
                                                                     class="comp-kzmu3c47 YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmu3c47"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmu3c47"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmu3c47inlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmu3c47inlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmu3c4b"
                                                                                 class="MazNVa comp-kzmu3c4b wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmu3c4b"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmu3c4b&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:960,&quot;targetHeight&quot;:714,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5526,&quot;height&quot;:4108,&quot;uri&quot;:&quot;888d49_a4a9ca2e4e5340638da94c86dd8253eb~mv2.jpg&quot;,&quot;name&quot;:&quot;agorcat-lantern-no-logo.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_a4a9ca2e4e5340638da94c86dd8253eb~mv2.jpg/v1/fill/w_1920,h_1428,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/agorcat-lantern-no-logo.jpg"
                                                                                            alt="agorcat-lantern-no-logo.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmux9qf"
                                                                                 class="KcpHeO tz5f0K comp-kzmux9qf wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Final Painting</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmu3c4e"
                                                                                 class="MazNVa comp-kzmu3c4e wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmu3c4e"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmu3c4e&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:714,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5526,&quot;height&quot;:4108,&quot;uri&quot;:&quot;888d49_c563bcda03d7499b9f692228637c7450~mv2.jpg&quot;,&quot;name&quot;:&quot;agorcat-lantern-callouts.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_c563bcda03d7499b9f692228637c7450~mv2.jpg/v1/fill/w_1922,h_1428,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/agorcat-lantern-callouts.jpg"
                                                                                            alt="agorcat-lantern-callouts.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmuyc11"
                                                                                 class="KcpHeO tz5f0K comp-kzmuyc11 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Callouts</span>
                                                                            </p></div>
                                                                            <div id="comp-l6y81v37"
                                                                                 class="MazNVa comp-l6y81v37 wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-l6y81v37"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-l6y81v37&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:714,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5526,&quot;height&quot;:4108,&quot;uri&quot;:&quot;888d49_5abe131de6ba450a89ae61a00ba80535~mv2.jpg&quot;,&quot;name&quot;:&quot;agorcat-lantern-callouts-path.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_5abe131de6ba450a89ae61a00ba80535~mv2.jpg/v1/fill/w_1922,h_1428,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/agorcat-lantern-callouts-path.jpg"
                                                                                            alt="agorcat-lantern-callouts-path.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-l6y84h60"
                                                                                 class="KcpHeO tz5f0K comp-l6y84h60 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Game Pathway</span>
                                                                            </p></div>
                                                                            <div id="comp-l6y865j9"
                                                                                 class="MazNVa comp-l6y865j9 wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-l6y865j9"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-l6y865j9&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:714,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5526,&quot;height&quot;:4108,&quot;uri&quot;:&quot;888d49_86cff3d7672540fe969ed15afe19fc60~mv2.jpg&quot;,&quot;name&quot;:&quot;agorcat-lantern-linework.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_86cff3d7672540fe969ed15afe19fc60~mv2.jpg/v1/fill/w_1922,h_1428,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/agorcat-lantern-linework.jpg"
                                                                                            alt="agorcat-lantern-linework.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-l6y88rjl"
                                                                                 class="KcpHeO tz5f0K comp-l6y88rjl wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Linework</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmu3c4h"
                                                                                 class="MazNVa comp-kzmu3c4h wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmu3c4h"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmu3c4h&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:680,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5526,&quot;height&quot;:4108,&quot;uri&quot;:&quot;888d49_f2b0bcf883e54ccc911af2cc24889347~mv2.png&quot;,&quot;name&quot;:&quot;final_render-diffuse-dir.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_f2b0bcf883e54ccc911af2cc24889347~mv2.png/v1/fill/w_1922,h_1360,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/final_render-diffuse-dir.png"
                                                                                            alt="final_render-diffuse-dir.png"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-l6y89bxt"
                                                                                 class="KcpHeO tz5f0K comp-l6y89bxt wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">3D Model</span>
                                                                            </p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmu3c4l"
                                                                     class="comp-kzmu3c4l YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmu3c4l"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmu3c4l"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmu3c4linlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmu3c4linlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmu3c4p"
                                                                                 class="KcpHeO tz5f0K comp-kzmu3c4p wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Lantern Shop - Opium Operation</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmu3c4s"
                                                                                 class="KcpHeO tz5f0K comp-kzmu3c4s wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Set Design</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmu3c4v"
                                                                                 class="KcpHeO tz5f0K comp-kzmu3c4v wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >A
                                                                                lantern shop in Guangzhou, China
                                                                                during the mid-1800 opium wars, Qing
                                                                                Dynasty. The lantern shop smuggles
                                                                                opium, which are hidden inside
                                                                                fish-shaped lanterns and sold to
                                                                                government officials and wealthy
                                                                                merchants.&nbsp;</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0oq2" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0oq2 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0oq2" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0oq2" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0oq2inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0oq2inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmpm3as"
                                                                 class="comp-kzmpm3as CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmpm3as" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmpm3as"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmpm3c3"
                                                                     class="comp-kzmpm3c3 YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmpm3c3"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmpm3c3"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmpm3c3inlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmpm3c3inlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmqrp1z"
                                                                                 class="MazNVa comp-kzmqrp1z wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqrp1z"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqrp1z&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:917,&quot;targetHeight&quot;:516,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1080,&quot;uri&quot;:&quot;888d49_28f23ab3256b4a48925c4ae740e78118~mv2.jpg&quot;,&quot;name&quot;:&quot;052421-3.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_28f23ab3256b4a48925c4ae740e78118~mv2.jpg/v1/fill/w_1834,h_1032,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/052421-3.jpg"
                                                                                            alt="052421-3.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmv1omy"
                                                                                 class="KcpHeO tz5f0K comp-kzmv1omy wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Final Painting</span>
                                                                            </p></div>
                                                                            <div id="comp-kzplonfr"
                                                                                 class="VideoPlayer2054936319__root comp-kzplonfr wixui-video-player">
                                                                                <div data-player-name="Playable"
                                                                                     data-testid="playable"
                                                                                     class="VideoPlayer2054936319__playerContainer">
                                                                                    <div
                                                                                        data-playable-hook="player-container"
                                                                                        dir="ltr"
                                                                                        data-playable-dir="ltr"
                                                                                        tabIndex="0"
                                                                                        class="T7h78h aBdaFm">
                                                                                        <div class="MwgZWA TcENkh"
                                                                                             data-playable-component="">
                                                                                            <div class="rkTLUy"
                                                                                                 data-playable-hook="debug-panel-close-button">
                                                                                                x
                                                                                            </div>
                                                                                            <pre class="LOpx5W"
                                                                                                 data-playable-hook="debug-panel-info-container"> </pre>
                                                                                        </div>
                                                                                        <div class="aMw4Jp"
                                                                                             data-playable-component=""></div>
                                                                                        <div
                                                                                            class="IiHgII vzxs2y cb9E5t dj2W_J"
                                                                                            data-playable-hook="screen-block"
                                                                                            data-playable-component="">
                                                                                            <canvas class="l9fM0h"
                                                                                                    data-playable-hook="background-canvas"
                                                                                                    width="917"
                                                                                                    height="556"></canvas>
                                                                                            <video preload="metadata"
                                                                                                   autoPlay="on"
                                                                                                   loop=""
                                                                                                   controls="true"
                                                                                                   playsInline="true"
                                                                                                   src="https://video.wixstatic.com/video/888d49_f38d3b28abbf44d6a3f61fcb41ed3ee9/1080p/mp4/file.mp4"
                                                                                                   class=""></video>
                                                                                        </div>
                                                                                        <div class="VH7UEc pE6VGN"
                                                                                             data-playable-component="">
                                                                                            <div class="P_YO_v"
                                                                                                 data-playable-hook="preview-full-size-frame"></div>
                                                                                        </div>
                                                                                        <div class="YNsbh9"
                                                                                             data-playable-component="">
                                                                                            <div
                                                                                                data-playable-hook="top-block"
                                                                                                class="W0t_ic">
                                                                                                <div class="BYXbSU"
                                                                                                     data-playable-hook="screen-top-background"></div>
                                                                                                <div class="jQne1L">
                                                                                                    <div
                                                                                                        class="jw9Kj9"
                                                                                                        data-playable-hook="live-indicator-container">
                                                                                                        <div
                                                                                                            class="BHUFEt A2xlHS">
                                                                                                            <button
                                                                                                                tabIndex="0"
                                                                                                                class="Gn8Gdq OebeZw"
                                                                                                                aria-label="Sync to live"
                                                                                                                data-playable-hook="live-indicator-button"
                                                                                                                disabled="true">
                                                                                                                Live
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="QAfchT FmiuH4"
                                                                                                        data-playable-hook="title-container">
                                                                                                        <div
                                                                                                            class="ZDJjun wix-playable--title-text"
                                                                                                            data-playable-hook="video-title">
                                                                                                            Your
                                                                                                            Video
                                                                                                            Title
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="O1vZ1d">
                                                                                                <div class="y_umMm"
                                                                                                     data-playable-component="">
                                                                                                    <div
                                                                                                        class="zllqXq L_hw7T"
                                                                                                        role="tooltip">
                                                                                                        <div
                                                                                                            class="w1jcvC"
                                                                                                            data-playable-hook="tooltip-inner">
                                                                                                            Unmute
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                data-playable-hook="bottom-block"
                                                                                                class="QV8pil Chkuci ojCqay">
                                                                                                <div class="IT9grE"
                                                                                                     data-playable-hook="screen-bottom-background"></div>
                                                                                                <div class="V93u41"
                                                                                                     data-playable-hook="progress-bar-container">
                                                                                                    <div
                                                                                                        data-playable-hook="progress-control"
                                                                                                        class="ctBuTJ"
                                                                                                        tabIndex="0"
                                                                                                        aria-valuetext="Already played 24.25%"
                                                                                                        aria-valuenow="24.25"
                                                                                                        data-playable-played-percent="24.25">
                                                                                                        <div
                                                                                                            class="gwBw85">
                                                                                                            <div
                                                                                                                class="u1j2LK WdW6QO"></div>
                                                                                                            <div
                                                                                                                data-playable-hook="progress-buffered"
                                                                                                                class="u1j2LK aJcGTx"
                                                                                                                style={{ width : '100%' }}></div>
                                                                                                            <div
                                                                                                                data-playable-hook="progress-seek-to"
                                                                                                                class="u1j2LK w5RHza wix-playable--progress-seek-to"
                                                                                                                style={{ width : '0%' }}></div>
                                                                                                            <div
                                                                                                                data-playable-hook="progress-played"
                                                                                                                class="u1j2LK acEE1I wix-playable--progress-played"
                                                                                                                style={{ transform : ' scaleX(0.242)' }}></div>
                                                                                                            <div
                                                                                                                data-playable-hook="progress-time-indicators"
                                                                                                                class="mKA4v0"></div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            data-playable-hook="progress-hitbox"
                                                                                                            class="O7e4CU"></div>
                                                                                                        <div
                                                                                                            data-playable-hook="progress-seek-button"
                                                                                                            class="mYR6uE"
                                                                                                            style={{ transform : ' translateX(212.672px)' }}>
                                                                                                            <div
                                                                                                                class="ctzVus wix-playable--progress-seek-btn"></div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            data-playable-hook="progress-sync-button"
                                                                                                            class="RTqtKv QOZHQ8"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="c2j4zU">
                                                                                                    <div
                                                                                                        class="FUpTp7"
                                                                                                        data-playable-hook="controls-container-left">
                                                                                                        <div
                                                                                                            class="QpVBT9"
                                                                                                            data-playable-hook="play-container">
                                                                                                            <div
                                                                                                                class="_90z7w"
                                                                                                                data-playable-hook="playback-control"
                                                                                                                data-playable-is-playing="true">
                                                                                                                <button
                                                                                                                    class="W2aIUN oSuh0p"
                                                                                                                    data-playable-hook="playback-control"
                                                                                                                    aria-label="Pause"
                                                                                                                    type="button"
                                                                                                                    tabIndex="0">
                                                                                                                    <div
                                                                                                                        class="KgK6KM">
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="_fnLlj"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                class="wix-playable--play-svg-fill"
                                                                                                                                d="M8 6v14l10.25-7.18L8 6z"></path>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="NNvLPR"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                class="wix-playable--play-svg-fill"
                                                                                                                                d="M10 7v21l16-11L10 7z"></path>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="gek6yh">
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="_fnLlj"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                class="wix-playable--play-svg-fill"
                                                                                                                                d="M15 6h3v14h-3V6zM8 6h3v14H8V6z"></path>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="NNvLPR"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                class="wix-playable--play-svg-fill"
                                                                                                                                d="M10 7h5v21h-5V7zm10 0h5v21h-5V7z"></path>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="yO0EFf"
                                                                                                            data-playable-hook="volume-container">
                                                                                                            <div
                                                                                                                class="dqR62d"
                                                                                                                data-playable-hook="volume-control"
                                                                                                                data-playable-volume-percent="0"
                                                                                                                data-playable-is-muted="true">
                                                                                                                <button
                                                                                                                    class="zuqkBx rnG1K6 XubB8i qJrrld"
                                                                                                                    data-playable-hook="mute-button"
                                                                                                                    aria-label="Unmute"
                                                                                                                    type="button"
                                                                                                                    tabIndex="0">
                                                                                                                    <div
                                                                                                                        class="ztCxq7 VTppQd">
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="aEw8y1"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                fill-rule="evenodd"
                                                                                                                                class="wix-playable--volume-svg-fill"
                                                                                                                                d="M 18.5 14.0476 L 16.154 16.3936 L 15.372 15.6116 L 17.718 13.2656 L 15.372 10.9196 L 16.154 10.1376 L 18.5 12.4836 L 20.846 10.1376 L 21.628 10.9196 L 19.282 13.2656 L 21.628 15.6116 L 20.846 16.3936 L 18.5 14.0476 Z M 7 9.76563 L 13 5.76563 L 13 19.7656 L 7 15.7656 L 4 15.7656 L 4 9.76563 L 7 9.76563 Z"></path>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="f6CCWN"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                fill-rule="evenodd"
                                                                                                                                class="wix-playable--volume-svg-fill"
                                                                                                                                d="M23.586 18.04l-2.829-2.828 1.415-1.415L25 16.626l2.828-2.83 1.415 1.416-2.829 2.828 2.829 2.828-1.415 1.415L25 19.453l-2.828 2.83-1.415-1.415 2.829-2.828zM9 13.04l7-5v19l-7-5H5v-9h4z"></path>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="ToIAK9">
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="aEw8y1"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                fill-rule="evenodd"
                                                                                                                                class="wix-playable--volume-svg-fill"
                                                                                                                                d="M 7 9.8025 L 13 5.8025 L 13 19.8025 L 7 15.8025 L 4 15.8025 L 4 9.8025 L 7 9.8025 Z M 17.243 17.4285 L 16.216 16.4025 A 4.548 4.548 0 0 0 16.216 9.9705 L 17.243 8.9435 A 6 6 0 0 1 17.243 17.4285 Z"></path>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="f6CCWN"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                class="wix-playable--volume-svg-fill"
                                                                                                                                d="M9 13l7-5v19l-7-5H5v-9h4zm12.884 11.407l-1.414-1.414a7.174 7.174 0 000-10.146l1.414-1.414a9.174 9.174 0 010 12.974z"
                                                                                                                                fill-rule="evenodd"></path>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="_7tzdU">
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="aEw8y1"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                fill-rule="evenodd"
                                                                                                                                class="wix-playable--volume-svg-fill"
                                                                                                                                d="M 7 9.8025 L 13 5.8025 L 13 19.8025 L 7 15.8025 L 4 15.8025 L 4 9.8025 L 7 9.8025 Z M 20.071 20.1975 L 19.047 19.1725 A 8.552 8.552 0 0 0 19.047 7.0795 L 20.071 6.0545 C 23.976 9.9605 23.976 16.2915 20.071 20.1975 Z M 17.243 17.4285 L 16.216 16.4025 A 4.548 4.548 0 0 0 16.216 9.9705 L 17.243 8.9435 A 6 6 0 0 1 17.243 17.4285 Z"></path>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            class="f6CCWN"
                                                                                                                            width="100%">
                                                                                                                            <path
                                                                                                                                fill-rule="evenodd"
                                                                                                                                class="wix-playable--volume-svg-fill"
                                                                                                                                d="M9 13.04l7-5v19l-7-5H5v-9h4zm16.897 15.794l-1.414-1.414c5.237-5.238 5.237-13.73 0-18.966l1.414-1.414c6.018 6.018 6.018 15.776 0 21.794zm-4.013-4.387l-1.414-1.414a7.174 7.174 0 000-10.146l1.414-1.414a9.174 9.174 0 010 12.974z"></path>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                </button>
                                                                                                                <div
                                                                                                                    class="Z8Ru_i"
                                                                                                                    data-playable-hook="volume-input-block"
                                                                                                                    aria-label="Volume control"
                                                                                                                    aria-valuemin="0"
                                                                                                                    aria-valuenow="0"
                                                                                                                    aria-valuemax="100"
                                                                                                                    tabIndex="0"
                                                                                                                    value="0"
                                                                                                                    aria-valuetext="Volume is undefined%">
                                                                                                                    <div
                                                                                                                        class="MRjoHU XbJXgH wix-playable--volume-progress-background"></div>
                                                                                                                    <div
                                                                                                                        class="MRjoHU MP5ZfL wix-playable--volume-progress"
                                                                                                                        data-playable-hook="volume-input"
                                                                                                                        style={{ width : '0%' }}></div>
                                                                                                                    <div
                                                                                                                        class="ylsu_r"
                                                                                                                        data-playable-hook="volume-hitbox"></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="lgDwRF"
                                                                                                            data-playable-hook="time-container">
                                                                                                            <div
                                                                                                                data-playable-hook="time-control"
                                                                                                                class="KpdQrc wix-playable--time-text"
                                                                                                                data-playable-current-time="3.233016"
                                                                                                                data-playable-duration="13.333334">
                                                                                                                    <span
                                                                                                                        data-playable-hook="current-time-indicator"
                                                                                                                        class="gkGtxN">00:03</span>
                                                                                                                <span
                                                                                                                    data-playable-hook="duration-time-indicator"
                                                                                                                    class="gkGtxN pkI6sF">00:13</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="llN1CZ"
                                                                                                        data-playable-hook="controls-container-right">
                                                                                                        <div
                                                                                                            class="wkFNGC"
                                                                                                            data-playable-hook="picture-in-picture-container">
                                                                                                            <div
                                                                                                                class="pAPwip SxDloc"
                                                                                                                data-playable-hook="picture-in-picture-control"
                                                                                                                data-is-picture-in-picture="false">
                                                                                                                <button
                                                                                                                    class="Fl7sUm nKEFMs"
                                                                                                                    data-playable-hook="picture-in-picture-control"
                                                                                                                    aria-label="Play Picture-in-Picture"
                                                                                                                    type="button"
                                                                                                                    tabIndex="0">
                                                                                                                    <div
                                                                                                                        class="Ukb5XC">
                                                                                                                        <svg
                                                                                                                            class="cwmXNz"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            width="100%">
                                                                                                                            <g fill-rule="evenodd"
                                                                                                                               class="wix-playable--picture-in-picture-svg-fill">
                                                                                                                                <path
                                                                                                                                    d="M11 19H5V6h15v5h-2V8H7v9h4v2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M13 13h9v8h-9v-8zm2 2v4h5v-4h-5z"></path>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            class="yVltgc"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            version="1"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            width="100%">
                                                                                                                            <g fill-rule="evenodd"
                                                                                                                               class="wix-playable--picture-in-picture-svg-fill">
                                                                                                                                <path
                                                                                                                                    d="M14 26H5V7h22v7h-2V9H7v15h7v2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M17 17h12v11H17V17zm2 2v7h8v-7h-8z"></path>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="ViNbIi">
                                                                                                                        <svg
                                                                                                                            class="cwmXNz"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            viewBox="0 0 26 26"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            width="100%">
                                                                                                                            <g fill-rule="evenodd"
                                                                                                                               class="wix-playable--picture-in-picture-svg-fill">
                                                                                                                                <path
                                                                                                                                    d="M12 19H5V6h15v5h-2V8H7v9h5v2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M21 15h-7v-2h7v2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M16 20v-7h-2v7h2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M21.279 18.95l-1.415 1.414-4.95-4.95L16.329 14l4.95 4.95z"></path>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                        <svg
                                                                                                                            class="yVltgc"
                                                                                                                            viewBox="0 0 35 35"
                                                                                                                            version="1"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            preserveAspectRatio="xMidYMin slice"
                                                                                                                            width="100%">
                                                                                                                            <g fill-rule="evenodd"
                                                                                                                               class="wix-playable--picture-in-picture-svg-fill">
                                                                                                                                <path
                                                                                                                                    d="M17 26H5V7h22v9h-2V9H7v15h10v2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M28 21h-8v-2h8v2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M22 27v-8h-2v8h2z"></path>
                                                                                                                                <path
                                                                                                                                    d="M29 27l-1 1-7-6 2-2 6 7z"></path>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="Y5bsSu"
                                                                                                            data-playable-hook="download-container">
                                                                                                            <div
                                                                                                                class="k85XnN">
                                                                                                                <button
                                                                                                                    class="urzrNl h4iEJm"
                                                                                                                    data-playable-hook="download-button"
                                                                                                                    aria-label="Download video"
                                                                                                                    type="button"
                                                                                                                    tabIndex="0">
                                                                                                                    <svg
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        viewBox="0 0 26 26"
                                                                                                                        class="VAQcIh"
                                                                                                                        width="100%">
                                                                                                                        <g fill-rule="evenodd"
                                                                                                                           class="wix-playable--download-svg-fill">
                                                                                                                            <path
                                                                                                                                d="M6 18h14v2H6v-2zm3.586-8.328L13 13.025v2.708l-4.828-4.647 1.414-1.414zM13 13.123l3.618-3.655 1.414 1.414L13 15.749v-2.626z"></path>
                                                                                                                            <path
                                                                                                                                d="M12 6h2v8l-1 2-1-2V6z"></path>
                                                                                                                        </g>
                                                                                                                    </svg>
                                                                                                                    <svg
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        viewBox="0 0 35 35"
                                                                                                                        class="R4TBlN"
                                                                                                                        width="100%">
                                                                                                                        <path
                                                                                                                            d="M16.5 17.586V7h2v10.825L23.571 13l1.414 1.414-7.085 6.743-.329.328-7.071-7.07L11.914 13l4.586 4.586zM5.5 26h24v2h-24v-2z"
                                                                                                                            fill-rule="evenodd"></path>
                                                                                                                    </svg>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="NuiShs"
                                                                                                            data-playable-hook="full-screen-container">
                                                                                                            <div
                                                                                                                class="hKOrCk"
                                                                                                                data-playable-hook="full-screen-control">
                                                                                                                <button
                                                                                                                    class="Cn9dEu SikRew"
                                                                                                                    data-playable-hook="full-screen-button"
                                                                                                                    aria-label="Enter full screen"
                                                                                                                    type="button"
                                                                                                                    tabIndex="0">
                                                                                                                    <svg
                                                                                                                        class="B_N5WW"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        viewBox="0 0 26 26"
                                                                                                                        preserveAspectRatio="xMidYMin slice"
                                                                                                                        width="100%">
                                                                                                                        <path
                                                                                                                            class="wix-playable--full-screen-svg-fill"
                                                                                                                            fill-rule="evenodd"
                                                                                                                            d="M8 16H6v4h4v-2H8v-2zM6 7v3h2V8h2V6H6v1zm14-1h-4v2h2v2h2V6zm-2 12h-2v2h4v-4h-2v2z"></path>
                                                                                                                    </svg>
                                                                                                                    <svg
                                                                                                                        class="D7xTDF"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        viewBox="0 0 35 35"
                                                                                                                        preserveAspectRatio="xMidYMin slice"
                                                                                                                        width="100%">
                                                                                                                        <path
                                                                                                                            class="wix-playable--full-screen-svg-fill"
                                                                                                                            fill-rule="evenodd"
                                                                                                                            d="M11 28h2v-6H7v2h4v4zm2-21h-2v4H7v2h6V7zm9 6h6v-2h-4V7h-2v6zm2 11h4v-2h-6v6h2v-4z"></path>
                                                                                                                    </svg>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="etMMn5"
                                                                                                        data-playable-hook="logo-container">
                                                                                                        <div
                                                                                                            class="A6A8Dz">
                                                                                                            <button
                                                                                                                class="enmd7C NspOOT"
                                                                                                                aria-label="Watch on site"
                                                                                                                type="button"
                                                                                                                tabIndex="0">
                                                                                                                <svg
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                    viewBox="0 0 26 26"
                                                                                                                    class="vRtcOZ"
                                                                                                                    preserveAspectRatio="xMidYMin slice"
                                                                                                                    width="100%">
                                                                                                                    <path
                                                                                                                        fill-rule="evenodd"
                                                                                                                        class="wix-playable--logo-button-svg-fill"
                                                                                                                        d="M8 20H6V6h4v2H8v10h10v-2h2v4H8zM18 9.485l-4.586 4.586L12 12.657 16.657 8h-1.653V6H20v5.005h-2v-1.52z"></path>
                                                                                                                </svg>
                                                                                                                <svg
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                    viewBox="0 0 35 35"
                                                                                                                    class="RBEUr7"
                                                                                                                    preserveAspectRatio="xMidYMin slice"
                                                                                                                    width="100%">
                                                                                                                    <path
                                                                                                                        fill-rule="evenodd"
                                                                                                                        class="wix-playable--logo-button-svg-fill"
                                                                                                                        d="M9 28H7V7h7.071v2H9v17h17v-5h2v7H9zm17-17.98l-8.586 8.587L16 17.192 24.192 9h-2.184V7H28v6.012h-2V10.02z"></path>
                                                                                                                </svg>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="HFEl1G qipy26"
                                                                                             data-playable-component=""
                                                                                             data-playable-hook="loader"></div>
                                                                                        <div
                                                                                            class="P9q5GM L8SYSN SWiF19"
                                                                                            data-playable-hook="overlay"
                                                                                            data-playable-component="">
                                                                                            <div class="jyZxBT"
                                                                                                 data-playable-hook="overlay-content"
                                                                                                 style={{ backgroundImage : ' none' }}></div>
                                                                                            <div class="oVB0VT"
                                                                                                 data-playable-hook="overlay-play-button">
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    viewBox="0 0 36 36"
                                                                                                    preserveAspectRatio="xMidYMin slice"
                                                                                                    width="100%"
                                                                                                    style={{ paddingBottom : ' 100%',  height: '1px', overflow : 'visible'}}>
                                                                                                    <g fill="none"
                                                                                                       fill-rule="evenodd">
                                                                                                        <circle cx="18"
                                                                                                                cy="18"
                                                                                                                r="17"
                                                                                                                class="wix-playable--overlay-play-svg-stroke"
                                                                                                                stroke-width="2"></circle>
                                                                                                        <path
                                                                                                            class="wix-playable--overlay-play-svg-fill"
                                                                                                            d="M23.935 17.708l-10.313 6.033V11.676z"></path>
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmpm3cu"
                                                                     class="comp-kzmpm3cu YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmpm3cu"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmpm3cu"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmpm3cuinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmpm3cuinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmpm3d2"
                                                                                 class="KcpHeO tz5f0K comp-kzmpm3d2 wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Mirage Palace</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmrpo29"
                                                                                 class="KcpHeO tz5f0K comp-kzmrpo29 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Environment Illustration</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmpm3d6"
                                                                                 class="KcpHeO tz5f0K comp-kzmpm3d6 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >A palace
                                                                                momentarily appears in a mirage. The
                                                                                weary desert travelers approach in
                                                                                hope of rest and restoration.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div id="comp-kzmv2235"
                                                             class="KcpHeO tz5f0K comp-kzmv2235 wixui-rich-text"
                                                             data-testid="richTextElement"><p
                                                            class="font_8 wixui-rich-text__text"
                                                            style={{ textAlign : 'center'}}><span
                                                            style={{ fontStyle : 'italic' }}
                                                            class="wixui-rich-text__text">3D Model </span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0oq3" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0oq3 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0oq3" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0oq3" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0oq3inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0oq3inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmqxh18"
                                                                 class="comp-kzmqxh18 CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmqxh18" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmqxh18"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmqxh2w"
                                                                     class="comp-kzmqxh2w YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmqxh2w"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmqxh2w"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmqxh2winlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmqxh2winlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmqxh35"
                                                                                 class="MazNVa comp-kzmqxh35 wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh35"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh35&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5208,&quot;height&quot;:2930,&quot;uri&quot;:&quot;888d49_b0d9f60f0bfe41c8b819cc86f38e1199~mv2.png&quot;,&quot;name&quot;:&quot;labyrinth2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_b0d9f60f0bfe41c8b819cc86f38e1199~mv2.png/v1/fill/w_1922,h_1082,al_c,q_95,usm_0.66_1.00_0.01,enc_auto/labyrinth2.png"
                                                                                            alt="labyrinth2.png"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmv4u80"
                                                                                 class="KcpHeO tz5f0K comp-kzmv4u80 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Final Painting</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmqxh3c"
                                                                                 class="MazNVa comp-kzmqxh3c wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png/v1/fill/w_959,h_540,al_c,lg_1,q_90,enc_auto/10_07_20_2.png"
                                                                                            alt="10.07.20_2.png"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmv6bip"
                                                                                 class="KcpHeO tz5f0K comp-kzmv6bip wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">3D Model - Test render</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmqxh3h"
                                                                                 class="MazNVa comp-kzmqxh3h wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3h"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3h&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_19b977375c3841249a617de5748dcfc0~mv2.jpg&quot;,&quot;name&quot;:&quot;test33.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_19b977375c3841249a617de5748dcfc0~mv2.jpg/v1/fill/w_959,h_540,al_c,lg_1,q_85,enc_auto/test33.jpg"
                                                                                            alt="test33.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmv6yx8"
                                                                                 class="KcpHeO tz5f0K comp-kzmv6yx8 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text">Test render + Composition exploration</span>
                                                                            </p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmqxh3n"
                                                                     class="comp-kzmqxh3n YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmqxh3n"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmqxh3n"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmqxh3ninlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmqxh3ninlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmqxh3t"
                                                                                 class="KcpHeO tz5f0K comp-kzmqxh3t wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Labyrinth of Lost Selves</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmrqvx8"
                                                                                 class="KcpHeO tz5f0K comp-kzmrqvx8 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Environment Illustration</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmqxh3z"
                                                                                 class="KcpHeO tz5f0K comp-kzmqxh3z wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >A
                                                                                labyrinth housing a villainous
                                                                                character who has forever lost a
                                                                                sense of self and place in life. The
                                                                                villain survives by feeding on the
                                                                                selves of others. The villain&#39;s
                                                                                conquests remain as statues that
                                                                                meld into the labyrinth.</p></div>
                                                                            <div id="comp-kzmvuzla"
                                                                                 class="KcpHeO tz5f0K comp-kzmvuzla wixui-rich-text"
                                                                                 data-testid="richTextElement"><h1
                                                                                class="font_0 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center',  fontSize : '30px'}}>
                                                                                <span
                                                                                    class="wixGuard wixui-rich-text__text"> </span>🏅
                                                                            </h1></div>
                                                                            <div id="comp-kzmvuzmb"
                                                                                 class="KcpHeO tz5f0K comp-kzmvuzmb wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontFamily : 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">This piece won <span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text">Runner Up</span> for the Learn Squared March 2021 &quot;Fantastical Moment&quot; competition.</span>
                                                                            </p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0oq4" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0oq4 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0oq4" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0oq4" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0oq4inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0oq4inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmrrypa"
                                                                 class="comp-kzmrrypa CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmrrypa" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmrrypa"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmrryqp"
                                                                     class="comp-kzmrryqp YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmrryqp"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmrryqp"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmrryqpinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmrryqpinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmrryqv"
                                                                                 class="MazNVa comp-kzmrryqv wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_57664facd28a456db701f1505aa7f766~mv2.jpg/v1/fill/w_1828,h_1029,al_c,q_85,enc_auto/agor-cat-image-recovered1-2xx.jpg"
                                                                                            alt="agor-cat-image-recovered1-2xx.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmrryr9"
                                                                     class="comp-kzmrryr9 YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmrryr9"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmrryr9"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmrryr9inlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmrryr9inlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmrryrf"
                                                                                 class="KcpHeO tz5f0K comp-kzmrryrf wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Water City</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmrryro"
                                                                                 class="KcpHeO tz5f0K comp-kzmrryro wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Environment Illustration</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmrryrk"
                                                                                 class="KcpHeO tz5f0K comp-kzmrryrk wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >A
                                                                                Venetian-inspired water city. This
                                                                                is the establishing shot for a short
                                                                                story that I&#39;m illustrating.</p>
                                                                            </div>
                                                                            <div id="comp-kzmrsz7d"
                                                                                 class="KcpHeO tz5f0K comp-kzmrsz7d wixui-rich-text"
                                                                                 data-testid="richTextElement"><h1
                                                                                class="font_0 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center',  fontSize : '30px'}}>
                                                                                🥇</h1></div>
                                                                            <div id="comp-kzmrsfrl"
                                                                                 class="KcpHeO tz5f0K comp-kzmrsfrl wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontFamily : 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">This piece won <span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text">1st place</span> for the Learn Squared May 2020 competition.</span>
                                                                            </p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0or" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0or wixui-section">
                                                <div id="bgLayers_comp-l6y7k0or" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0or" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0orinlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0orinlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzms359k"
                                                                 class="comp-kzms359k CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzms359k" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzms359k"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzms35bf"
                                                                     class="comp-kzms35bf YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzms35bf"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzms35bf"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzms35bfinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzms35bfinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzms35bm"
                                                                                 class="MazNVa comp-kzms35bm wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzms35bm"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzms35bm&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:721,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1440,&quot;uri&quot;:&quot;888d49_4af23c5d85994305b9cfd1fed50e12dd~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-done1.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_4af23c5d85994305b9cfd1fed50e12dd~mv2.jpg/v1/fill/w_1919,h_1440,al_c,q_90,enc_auto/agor-cat-done1.jpg"
                                                                                            alt="agor-cat-done1.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzms35bt"
                                                                     class="comp-kzms35bt YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzms35bt"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzms35bt"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzms35btinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzms35btinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzms35c0"
                                                                                 class="KcpHeO tz5f0K comp-kzms35c0 wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Pig Spirit Roaster</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzms35c6"
                                                                                 class="KcpHeO tz5f0K comp-kzms35c6 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Prop Design</span>
                                                                            </p></div>
                                                                            <div id="comp-kzms35cc"
                                                                                 class="KcpHeO tz5f0K comp-kzms35cc wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >A sacred
                                                                                roaster that takes a pig&#39;s dead
                                                                                body and living human sacrifice. It
                                                                                transfers the human&#39;s soul into
                                                                                the pig&#39;s body to be released
                                                                                into the spirit realm. A bit of
                                                                                deception involved, as the human(s)
                                                                                are unaware that the roaster does
                                                                                this exchange and instead believe
                                                                                the roaster accepts food offerings
                                                                                to perform a ritual.</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0or1" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0or1 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0or1" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0or1" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0or1inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0or1inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmtaejx"
                                                                 class="comp-kzmtaejx CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmtaejx" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmtaejx"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmtaenx"
                                                                     class="comp-kzmtaenx YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmtaenx"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmtaenx"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmtaenxinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmtaenxinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content"></div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmtaep5"
                                                                     class="comp-kzmtaep5 YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmtaep5"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmtaep5"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmtaep5inlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmtaep5inlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmtaepa"
                                                                                 class="KcpHeO tz5f0K comp-kzmtaepa wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Home Interior Design</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmtaepn"
                                                                                 class="KcpHeO tz5f0K comp-kzmtaepn wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Set Design</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmtaeps"
                                                                                 class="KcpHeO tz5f0K comp-kzmtaeps wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                >Personal
                                                                                project to design the interior of a
                                                                                2-story home. The house is similar
                                                                                to a lighthouse, situated by the
                                                                                ocean. If you climb to the uppermost
                                                                                level (like a balcony) you have a
                                                                                nice view.</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div id="comp-kzmtaeoc"
                                                             class="MazNVa comp-kzmtaeoc wixui-image">
                                                            <div data-testid="linkElement" class="j7pOnl">
                                                                <wow-image id="img_comp-kzmtaeoc"
                                                                           class="HlRz5e BI8PVQ"
                                                                           data-image-info="{&quot;containerId&quot;:&quot;comp-kzmtaeoc&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:1014,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1620,&quot;height&quot;:1710,&quot;uri&quot;:&quot;888d49_ddc19e9176cb4f0c84d3f35ce1241c87~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-house.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                           data-bg-effect-name=""
                                                                           data-has-ssr-src="true"><img
                                                                    src="https://static.wixstatic.com/media/888d49_ddc19e9176cb4f0c84d3f35ce1241c87~mv2.jpg/v1/fill/w_1620,h_1709,al_c,q_90,enc_auto/agor-cat-house.jpg"
                                                                    alt="agor-cat-house.jpg"
                                                                    style={{ width : '100%', height : '100%', objectFit : 'cover', objectPosition : '50% 50%' }}/>
                                                                </wow-image>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}