import React, { Component } from 'react';

export default class Content extends Component {
    render() {
        return(
            <main id="PAGES_CONTAINER" class="PAGES_CONTAINER" tabIndex="-1" data-main-content="true">
                <div id="SITE_PAGES" data-page-transition="OutIn" class="JshATs SITE_PAGES">
                    <div id="SITE_PAGES_TRANSITION_GROUP" class="fcNEqv">
                        <div id="c1lo9" class="dBAkHi c1lo9">
                            <div class="HT5ybB">
                                <div id="Containerc1lo9" class="Containerc1lo9 SPY_vo">
                                    <div data-mesh-id="Containerc1lo9inlineContent" data-testid="inline-content"
                                         class="">
                                        <div data-mesh-id="Containerc1lo9inlineContent-gridContainer"
                                             data-testid="mesh-container-content">
                                            <section id="comp-l6y7k0oq3" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0oq3 wixui-section"
                                                     style={{marginTop: '28px'}}>
                                                <div id="bgLayers_comp-l6y7k0oq3" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0oq3" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0oq3inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0oq3inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmqxh18"
                                                                 class="comp-kzmqxh18 CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmqxh18" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmqxh18"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmqxh2w"
                                                                     class="comp-kzmqxh2w YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmqxh2w"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmqxh2w"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmqxh2winlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmqxh2winlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmqxh35"
                                                                                 class="MazNVa comp-kzmqxh35 wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh35"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh35&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5208,&quot;height&quot;:2930,&quot;uri&quot;:&quot;888d49_b0d9f60f0bfe41c8b819cc86f38e1199~mv2.png&quot;,&quot;name&quot;:&quot;labyrinth2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_f8e403f2fe4342b9a82eb7125928a5fe~mv2.png/v1/fill/w_961,h_541,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_f8e403f2fe4342b9a82eb7125928a5fe~mv2.png"
                                                                                            alt="allen-title-card.png"
                                                                                            style={{ width : '100%', height : '100%', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmv4u80h"
                                                                                 class="KcpHeO tz5f0K comp-kzmv4u80 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                                style={{ textAlign : 'center'}}>
                                                                                    <span style={{ fontStyle : 'italic' }}
                                                                                          class="wixui-rich-text__text"></span>
                                                                            </p></div>
                                                                            <div id="comp-kzmqxh35"
                                                                                 className="MazNVa comp-kzmqxh35 wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh35"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh35&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:5208,&quot;height&quot;:2930,&quot;uri&quot;:&quot;888d49_b0d9f60f0bfe41c8b819cc86f38e1199~mv2.png&quot;,&quot;name&quot;:&quot;labyrinth2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_f8e403f2fe4342b9a82eb7125928a5fe~mv2.png/v1/fill/w_961,h_541,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_f8e403f2fe4342b9a82eb7125928a5fe~mv2.png"
                                                                                            alt="allen-title-card.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                            <div id="comp-kzmqxh3c"
                                                                                 class="MazNVa comp-kzmqxh3c wixui-image"
                                                                            style={{height: 'revert'}}>
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189651003251896400/Screen_Shot_2023-12-01_at_5.53.02_PM.png?ex=659eefd5&is=658c7ad5&hm=af7d91f8ccbd2a89fa6ad0ed8b2b72ea392c02b6b62176e03cf3779468ffb277&"
                                                                                            alt="entrance-painted.png"
                                                                                            style={{ width : '100%', height : '100%', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_78026b614fa54c348b9629daa123b799~mv2.png/v1/fill/w_961,h_650,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_78026b614fa54c348b9629daa123b799~mv2.png"
                                                                                            alt="entrance-apartment-layout.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189651004455653468/Screen_Shot_2023-12-01_at_5.53.31_PM.png?ex=659eefd6&is=658c7ad6&hm=8da8658531f8ad0e774c9c4c22b73bfbd6372fd14e48f8960653c005bfaf393c&"
                                                                                            alt="couch-painted.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189410346142814338/apartment-int-kitchen-downscaled.png?ex=659e0fb4&is=658b9ab4&hm=e4eaa8ad72757134db68606e87edfbc3094ac6b2f9232f5935fa7b50d0d7f85d&"
                                                                                            alt="kitchen-layout.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189651005164486716/Screen_Shot_2023-12-01_at_5.53.50_PM.png?ex=659eefd6&is=658c7ad6&hm=8e2c88432cd3ab042f084cab3dff0eb4fe2a4e1b5095fee735f13f73ae9813bc&"
                                                                                            alt="living-room-painted.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_7bcb1ff4a1e9402b92c9e57b78faa851~mv2.png/v1/fill/w_961,h_643,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_7bcb1ff4a1e9402b92c9e57b78faa851~mv2.png"
                                                                                            alt="living-room-layout-2.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_7719beb2b60c4a38874e250b68fbe791~mv2.png/v1/fill/w_961,h_643,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_7719beb2b60c4a38874e250b68fbe791~mv2.png"
                                                                                            alt="living-room-layout-2.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189651006099828806/Screen_Shot_2023-12-01_at_5.54.34_PM.png?ex=659eefd6&is=658c7ad6&hm=a04ae2ad4270e9e9d696d24af01f78b23c3abea236b195b11354e444068b070f&"
                                                                                            alt="bedroom-painted.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_6cf3fbc26a24451fb02be24dc3a0773b~mv2.png/v1/fill/w_961,h_643,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_6cf3fbc26a24451fb02be24dc3a0773b~mv2.png"
                                                                                            alt="bedroom-layout.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189651006779297892/Screen_Shot_2023-12-26_at_5.16.31_PM.png?ex=659eefd6&is=658c7ad6&hm=ac05ecae32bc6f8fe6708fe1b6c8cfa6cbb58596b6e2c249b92d37bcfa40b193&"
                                                                                            alt="exterior-painted.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmqxh3c"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmqxh3c&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:800,&quot;height&quot;:450,&quot;uri&quot;:&quot;888d49_40481d44b9e740f9b24f5bc751ded1d7~mv2.png&quot;,&quot;name&quot;:&quot;10.07.20_2.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_07184515530945c2a5a55c8869276531~mv2.jpg/v1/fill/w_961,h_614,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_07184515530945c2a5a55c8869276531~mv2.jpg"
                                                                                            alt="exterior-layout.png"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmqxh3n"
                                                                     class="comp-kzmqxh3n YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmqxh3n"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmqxh3n"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmqxh3ninlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmqxh3ninlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmqxh3t"
                                                                                 class="KcpHeO tz5f0K comp-kzmqxh3t wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                            ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Season 2: Invincible (TV series)</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmrqvx8"
                                                                                 class="KcpHeO tz5f0K comp-kzmrqvx8 wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                            ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Skybound Animation Production</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmqxh3z"
                                                                                 class="KcpHeO tz5f0K comp-kzmqxh3z wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                            ><span style={{ fontWeight : 'bold' }}>Sci-fi Apartment</span> - Pre-production background designs and layouts done for the <span style={{ fontStyle : 'italic' }}> Allen the Alien</span> episode in Season 2 of Invincible. I designed and 3d-modeled Allen's sci-fi apartment in its interior (kitchen, living room, bedroom, corridors) and exterior views.</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0oq4" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0oq4 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0oq4" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0oq4" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0oq4inlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0oq4inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzmrrypa"
                                                                 class="comp-kzmrrypa CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzmrrypa" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzmrrypa"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzmrryqp"
                                                                     class="comp-kzmrryqp YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmrryqp"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmrryqp"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmrryqpinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmrryqpinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmrryqv"
                                                                                 class="MazNVa comp-kzmrryqv wixui-image"
                                                                                    style={{ height: 'revert' }}>
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_183fe40b692249cd8c2f88a50a2fcd58~mv2.png/v1/fill/w_961,h_541,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Atom-eve-title-card.png"
                                                                                            alt="atom-eve-title-card.jpg"
                                                                                            style={{ width : '100%', height : '100%', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_2eddee6357904a26bb293d59f2302be9~mv2.png/v1/fill/w_961,h_541,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Neighborhood-night-painted.png"
                                                                                            alt="neighborhood-night-painted.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_99cb9c41fd4542d1a409869c0e764d6f~mv2.jpg/v1/fill/w_961,h_610,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_99cb9c41fd4542d1a409869c0e764d6f~mv2.jpg"
                                                                                            alt="neighborhood-night-layout.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_355dd5dc51fe471dabad4f9060632305~mv2.jpg/v1/fill/w_961,h_610,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_355dd5dc51fe471dabad4f9060632305~mv2.jpg"
                                                                                            alt="neighborhood-day-layout.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_984b512bcde642d5966999a231807dc3~mv2.png/v1/fill/w_961,h_600,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_984b512bcde642d5966999a231807dc3~mv2.png"
                                                                                            alt="eve-squirrel-painted.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_8f2b9f2244bb476d9a65cd83e5551e99~mv2.jpg/v1/fill/w_961,h_611,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_8f2b9f2244bb476d9a65cd83e5551e99~mv2.jpg"
                                                                                            alt="eve-squirrel-layout.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_68d2dc515ee24075b90c3d420afe2e0e~mv2.png/v1/fill/w_961,h_542,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_68d2dc515ee24075b90c3d420afe2e0e~mv2.png"
                                                                                            alt="eve-man-encounter-painted.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_46fdfcf441894089a06dc15cbc80bf8a~mv2.jpg/v1/fill/w_961,h_612,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_46fdfcf441894089a06dc15cbc80bf8a~mv2.jpg"
                                                                                            alt="eve-man-encounter-layout.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_ee7089847e2847f99041acafc6ce98ea~mv2.png/v1/fill/w_961,h_527,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_ee7089847e2847f99041acafc6ce98ea~mv2.png"
                                                                                            alt="homeless-encampment-painted.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_30224fb83d3f4d6f80b7a588ae8cfb48~mv2.jpg/v1/fill/w_961,h_598,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_30224fb83d3f4d6f80b7a588ae8cfb48~mv2.jpg"
                                                                                            alt="homeless-encampment-layout.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_d630458a929a49c1928dbeb03c588d7b~mv2.png/v1/fill/w_961,h_598,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_d630458a929a49c1928dbeb03c588d7b~mv2.png"
                                                                                            alt="eve-fly-city-painted.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                                <div data-testid="linkElement"
                                                                                     className="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzmrryqv"
                                                                                        className="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzmrryqv&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:541,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1029,&quot;uri&quot;:&quot;888d49_57664facd28a456db701f1505aa7f766~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-image-recovered1-2xx.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://static.wixstatic.com/media/888d49_68574fe0cdfc48ee99b0faed6f08c810~mv2.png/v1/fill/w_961,h_578,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/888d49_68574fe0cdfc48ee99b0faed6f08c810~mv2.png"
                                                                                            alt="eve-fly-city-layout.jpg"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectPosition: '50% 50%'
                                                                                            }}/>
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzmrryr9"
                                                                     class="comp-kzmrryr9 YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzmrryr9"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzmrryr9"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzmrryr9inlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzmrryr9inlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzmrryrf"
                                                                                 class="KcpHeO tz5f0K comp-kzmrryrf wixui-rich-text"
                                                                                 data-testid="richTextElement"><h2
                                                                                class="font_2 wixui-rich-text__text"
                                                                            ><span
                                                                                style={{ fontWeight : 'bold' }}
                                                                                class="wixui-rich-text__text"><span
                                                                                style={{ fontFamily : 'helvetica-w01-light,helvetica-w02-light,sans-serif' }}
                                                                                class="wixui-rich-text__text">Season 2: Invincible (TV series)</span></span>
                                                                            </h2></div>
                                                                            <div id="comp-kzmrryro"
                                                                                 class="KcpHeO tz5f0K comp-kzmrryro wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                            ><span
                                                                                style={{ fontStyle : 'italic' }}
                                                                                class="wixui-rich-text__text">Skybound Animation Production</span>
                                                                            </p></div>
                                                                            <div id="comp-kzmrryrk"
                                                                                 class="KcpHeO tz5f0K comp-kzmrryrk wixui-rich-text"
                                                                                 data-testid="richTextElement"><p
                                                                                class="font_8 wixui-rich-text__text"
                                                                            ><span style={{ fontWeight : 'bold' }}>Atom Eve BG Layouts</span> - Pre-production background designs and layouts done for the special prequel <span style={{ fontStyle : 'italic' }}> Atom Eve</span> episode in Season 2 of Invincible. I designed and 3d-modeled Eve's suburban neighborhood, a homeless encampment in the city, and a skyscraper fly-through.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0or" tabIndex="-1"
                                                     class="Oqnisf comp-l6y7k0or wixui-section">
                                                <div id="bgLayers_comp-l6y7k0or" data-hook="bgLayers"
                                                     class="MW5IWV">
                                                    <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0or" class="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0orinlineContent"
                                                     data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-l6y7k0orinlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <section id="comp-kzms359k"
                                                                 class="comp-kzms359k CohWsy wixui-column-strip">
                                                            <div id="bgLayers_comp-kzms359k" data-hook="bgLayers"
                                                                 class="if7Vw2">
                                                                <div data-testid="colorUnderlay"
                                                                     class="tcElKx i1tH8h"></div>
                                                                <div id="bgMedia_comp-kzms359k"
                                                                     class="wG8dni"></div>
                                                            </div>
                                                            <div data-testid="columns" class="V5AUxf">
                                                                <div id="comp-kzms35bf"
                                                                     class="comp-kzms35bf YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzms35bf"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzms35bf"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzms35bfinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzms35bfinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                            <div id="comp-kzms35bm"
                                                                                 class="MazNVa comp-kzms35bm wixui-image">
                                                                                <div data-testid="linkElement"
                                                                                     class="j7pOnl">
                                                                                    <wow-image
                                                                                        id="img_comp-kzms35bm"
                                                                                        class="HlRz5e BI8PVQ"
                                                                                        data-image-info="{&quot;containerId&quot;:&quot;comp-kzms35bm&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:961,&quot;targetHeight&quot;:721,&quot;isLQIP&quot;:true,&quot;imageData&quot;:{&quot;width&quot;:1920,&quot;height&quot;:1440,&quot;uri&quot;:&quot;888d49_4af23c5d85994305b9cfd1fed50e12dd~mv2.jpg&quot;,&quot;name&quot;:&quot;agor-cat-done1.jpg&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                        data-bg-effect-name=""
                                                                                        data-has-ssr-src="true">
                                                                                        <img
                                                                                            src="https://cdn.discordapp.com/attachments/1044752966105702540/1189703525719097344/Screen_Shot_2023-12-01_at_5.45.02_PM.png?ex=659f20c0&is=658cabc0&hm=bd53a8645e97331196025dc4a1ba15133e2661206dee002b0912f14b285337c2&"
                                                                                            alt="credits.jpg"
                                                                                            style={{ width : '100%', height : 'revert', objectPosition : '50% 50%' }} />
                                                                                    </wow-image>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="comp-kzms35bt"
                                                                     class="comp-kzms35bt YzqVVZ wixui-column-strip__column">
                                                                    <div id="bgLayers_comp-kzms35bt"
                                                                         data-hook="bgLayers" class="MW5IWV">
                                                                        <div data-testid="colorUnderlay"
                                                                             class="LWbAav Kv1aVt"></div>
                                                                        <div id="bgMedia_comp-kzms35bt"
                                                                             class="VgO9Yg"></div>
                                                                    </div>
                                                                    <div data-mesh-id="comp-kzms35btinlineContent"
                                                                         data-testid="inline-content" class="">
                                                                        <div
                                                                            data-mesh-id="comp-kzms35btinlineContent-gridContainer"
                                                                            data-testid="mesh-container-content">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}