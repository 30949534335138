import React from 'react';

export const HeaderMenuData = [
  {
    title: 'Portfolio',
    path: '/',
    testId: 'itemContentWrapper-0',
    testIdMobile: 'tinymenu-item-0-selected'
  },
  {
    title: 'Productions',
    path: '/production',
    testId: 'itemContentWrapper-1',
    testIdMobile: 'tinymenu-item-1'
  },
  {
    title: 'About',
    path: '/about',
    testId: 'itemContentWrapper-2',
    testIdMobile: 'tinymenu-item-2'
  }
];