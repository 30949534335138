import React, { Component } from 'react';

export default class Footer extends Component {

    render() {
        return(
            <footer class="SITE_FOOTER_WRAPPER" tabIndex="-1" id="SITE_FOOTER_WRAPPER">
                <div id="SITE_FOOTER" class="xU8fqS SITE_FOOTER wixui-footer" tabIndex="-1">
                    <div class="U4Bvut">
                        <div class="G5K6X8">
                            <div class="gUbusX" data-testid="screenWidthContainerBgCenter"></div>
                        </div>
                        <div class="CJF7A2">
                            <div data-mesh-id="SITE_FOOTERinlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="SITE_FOOTERinlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                    <div id="WRchTxt0-16wb" class="KcpHeO tz5f0K WRchTxt0-16wb wixui-rich-text"
                                         data-testid="richTextElement"><p class="font_9 wixui-rich-text__text"
                                                                          style={{lineHeight : '1.7em', textAlign : 'center', fontSize : '13px' }}>
                                        <span style={{letterSpacing: 'normal' }} class="wixui-rich-text__text">&copy; 2024 by Catherine Agor</span>
                                    </p></div>
                                    <div id="comp-kzrl492y" class="comp-kzrl492y WzbAF8">
                                        <ul class="mpGTIt" aria-label="Social Bar">
                                            <li id="dataItem-kzrl49471-comp-kzrl492y" class="O6KwRn"><a
                                                data-testid="linkElement"
                                                href="https://www.artstation.com/agorcat/" target="_blank"
                                                rel="noreferrer noopener" class="oRtuWN">
                                                <wow-image id="img_0_comp-kzrl492y" class="HlRz5e YaS0jR"
                                                           data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrl49471-comp-kzrl492y&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:94,&quot;height&quot;:94,&quot;uri&quot;:&quot;888d49_beb33fda134e43f29073ba02fde35cb2~mv2.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                           data-bg-effect-name="" data-has-ssr-src=""
                                                           ><img
                                                    alt="ArtStation-logomark-white1"/></wow-image>
                                            </a></li>
                                            <li id="dataItem-kzrl494a-comp-kzrl492y" class="O6KwRn"><a
                                                data-testid="linkElement"
                                                href="https://www.instagram.com/agor.cat/" target="_blank"
                                                rel="noreferrer noopener" class="oRtuWN">
                                                <wow-image id="img_1_comp-kzrl492y" class="HlRz5e YaS0jR"
                                                           data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrl494a-comp-kzrl492y&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:200,&quot;height&quot;:200,&quot;uri&quot;:&quot;e1aa082f7c0747168d9cf43e77046142.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                           data-bg-effect-name="" data-has-ssr-src=""><img
                                                    alt="Instagram"/></wow-image>
                                            </a></li>
                                            <li id="dataItem-kzrl494b-comp-kzrl492y" class="O6KwRn"><a
                                                data-testid="linkElement"
                                                href="https://www.youtube.com/channel/UCZhJmYyC6CC1gKrCx-l0ukQ"
                                                target="_blank" rel="noreferrer noopener" class="oRtuWN">
                                                <wow-image id="img_2_comp-kzrl492y" class="HlRz5e YaS0jR"
                                                           data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrl494b-comp-kzrl492y&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:200,&quot;height&quot;:200,&quot;uri&quot;:&quot;45bce1d726f64f1999c49feae57f6298.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                           data-bg-effect-name="" data-has-ssr-src=""><img
                                                    alt="YouTube"/></wow-image>
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}