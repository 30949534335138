import React, { Component, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderMenuData } from './HeaderMenuData';

export default class Header extends Component {
    constructor(){
        super();

        this.state = {
            black: true
        }
    }

    handleClick = () => {
        this.setState({black: !this.state.black})
    };

    render() {
        let nav_class = this.state.black ? "TINY_MENU ccKHnD XGr1CO" : "TINY_MENU ccKHnD XGr1CO IQ5y6N";

        return(
            <header class="SITE_HEADER_WRAPPER" tabIndex="-1" id="SITE_HEADER_WRAPPER">
                <div id="SITE_HEADER" class="xU8fqS SITE_HEADER wixui-header" tabIndex="-1">
                    <div class="_C0cVf">
                        <div class="_4XcTfy" data-testid="screenWidthContainerBg"></div>
                    </div>
                    <div class="U4Bvut">
                        <div class="G5K6X8">
                            <div class="gUbusX" data-testid="screenWidthContainerBgCenter"></div>
                        </div>
                        <div class="CJF7A2">
                            <div data-mesh-id="SITE_HEADERinlineContent" data-testid="inline-content" class="">
                                <div data-mesh-id="SITE_HEADERinlineContent-gridContainer"
                                     data-testid="mesh-container-content">
                                    <div id="i17sfndo" class="nJ7HCU i17sfndo">
                                        <div class="zUNKDy wixui-box" data-testid="container-bg"></div>
                                        <div data-mesh-id="i17sfndoinlineContent" data-testid="inline-content"
                                             class="">
                                            <div data-mesh-id="i17sfndoinlineContent-gridContainer"
                                                 data-testid="mesh-container-content">
                                                <div id="comp-kzmvf9th"
                                                     class="MazNVa comp-kzmvf9th wixui-image">
                                                    <div data-testid="linkElement" class="j7pOnl">
                                                        <wow-image id="img_comp-kzmvf9th" class="HlRz5e BI8PVQ">
                                                            <img
                                                            src="https://static.wixstatic.com/media/888d49_a625fd6ffb3143d59bc547775410eee1~mv2.jpg/v1/crop/x_433,y_0,w_3634,h_3634/fill/w_180,h_180,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/v6.jpg"
                                                            alt="v6.jpg"
                                                            srcSet="https://static.wixstatic.com/media/888d49_a625fd6ffb3143d59bc547775410eee1~mv2.jpg/v1/crop/x_433,y_0,w_3634,h_3634/fill/w_180,h_180,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/v6.jpg 1x, https://static.wixstatic.com/media/888d49_a625fd6ffb3143d59bc547775410eee1~mv2.jpg/v1/crop/x_433,y_0,w_3634,h_3634/fill/w_180,h_180,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/v6.jpg 2x"
                                                            fetchpriority="high"/>
                                                        </wow-image>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="i0q988b7" class="KcpHeO tz5f0K i0q988b7 wixui-rich-text"
                                         data-testid="richTextElement"><h1 class="font_0 wixui-rich-text__text"
                                                                            style={{ lineHeight: '1.1em', fontSize: '30px' }}
                                    >
                                        <span style={{ letterSpacing: '0.2em'}} class="wixui-rich-text__text">
                                            <span style={{ fontFamily : 'futura-lt-w01-book, futura-lt-w05-book, sans-serif' }}
                                            class="wixui-rich-text__text">CATHERINE AGOR</span></span></h1>
                                    </div>
                                    <div id="comp-kzmuhiff" class="KcpHeO tz5f0K comp-kzmuhiff wixui-rich-text"
                                         data-testid="richTextElement"><h2 class="font_2 wixui-rich-text__text"
                                                                           style={{ fontSize: '18px'}}>
                                        <span style={{ fontFamily: 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                        class="wixui-rich-text__text">Set &amp; Environment Designer</span>
                                    </h2></div>
                                    <nav id="i0xnayam" aria-label="Site"
                                         class="cVIbCE JSONnJ i0xnayam wixui-vertical-menu" tabIndex="-1">
                                        <ul>
                                            {HeaderMenuData.map((item, index) => {
                                                return (
                                                    <li key={index} className="fc6UbE wixui-vertical-menu__item">
                                                        <div data-testid={item.testId} className="j6vJM0">
                                                            <NavLink to={item.path} className="header-menu-item">
                                                                <span class="RS1d3G">{item.title}</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="xG85Hx"></div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </nav>
                                    <nav id="TINY_MENU" className={nav_class} aria-label="Site">
                                        <div className="fullScreenOverlay T8yEb4">
                                            <div className="fullScreenOverlayContent pVVzFv">
                                                <div className="s5PSQY">
                                                    <div className="Cy6gaZ">
                                                        <ul id="menuItemsTINY_MENU" className="b8N6Gv XGr1CO maQH15">
                                                            {HeaderMenuData.map((item, index) => {
                                                                return (
                                                                    <li key={index} className="h2447s"
                                                                        data-testid={item.testIdMobile}>
                                                                        <a onClick={this.handleClick}
                                                                            data-testid="linkElement" className="Ugdwow">
                                                                            <NavLink to={item.path} className="header-menu-item">
                                                                                <span class="RS1d3G">{item.title}</span>
                                                                            </NavLink>
                                                                        </a>
                                                                        <div className="xG85Hx"></div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button onClick={this.handleClick} id="menuButton" type="button" aria-haspopup="true" data-testid="tinymenu-menubutton"
                                                className="I9Hzr2">
                                            <svg className="r0KskF" preserveAspectRatio="none" viewBox="0 0 17 17">
                                                <line className="aT9KEY JIZnU5" x2="100%"></line>
                                                <line className="HD65dm JIZnU5" x2="100%"></line>
                                                <line className="sccRd9 JIZnU5" x2="100%"></line>
                                            </svg>
                                        </button>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
