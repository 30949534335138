import Header from './Header';
import Content from './Content';
import Production from './Production';
import About from './About';
import Footer from './Footer';
import {HashRouter as Router, Routes, Route} from "react-router-dom";

import './App.css';
import './mobile.css';

function App() {
  return (
      <div id="SITE_CONTAINER">
          <div id="main_MF" class="wix-global-css">
              <div id="SCROLL_TO_TOP" class="Vd6aQZ ignore-focus SCROLL_TO_TOP" tabIndex="-1" role="region"
                   aria-label="top of page"><span class="mHZSwn">top of page</span></div>
              <div id="BACKGROUND_GROUP" class="BACKGROUND_GROUP">
                  <div id="BACKGROUND_GROUP_TRANSITION_GROUP">
                      <div id="pageBackground_c1lo9" data-media-height-override-type=""
                           data-media-position-override="false"
                           class="pageBackground_c1lo9 BmZ5pC">
                          <div id="bgLayers_pageBackground_c1lo9" data-hook="bgLayers" class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_pageBackground_c1lo9" class="VgO9Yg"></div>
                              <div data-testid="bgOverlay" class="m4khSP"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div id="site-root" class="site-root">
                  <div id="masterPage" class="mesh-layout masterPage">
                      <Router>
                          <Header />
                          <div id="SITE_HEADER-placeholder" className="SITE_HEADER-placeholder"></div>
                          <Routes>
                              <Route path='/' exact element={<Content />} />
                              <Route path='/production' element={<Production />} />
                              <Route path='/about' element={<About />} />
                              {/*<Route path='/products' component={Products} />*/}
                          </Routes>
                          <Footer />
                      </Router>
                      {/*<Header />*/}
                      {/*<div id="SITE_HEADER-placeholder" className="SITE_HEADER-placeholder"></div>*/}
                      {/*<Content />*/}
                  </div>
              </div>
          </div>
      </div>
  );
}

export default App;
