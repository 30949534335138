import React, { Component } from 'react';

import './About.css';
import './mobile-about.css';

export default class About extends Component {
    render() {
        return(
            <main id="PAGES_CONTAINER" class="PAGES_CONTAINER" tabIndex="-1" data-main-content="true">
                <div id="SITE_PAGES" data-page-transition="OutIn" className="JshATs SITE_PAGES SITE_PAGES--ABOUT">
                    <div id="SITE_PAGES_TRANSITION_GROUP" className="fcNEqv">
                        <div id="ck70" className="dBAkHi ck70">
                            <div className="HT5ybB">
                                <div id="Containerck70" className="Containerck70 SPY_vo">
                                    <div data-mesh-id="Containerck70inlineContent" data-testid="inline-content" className="">
                                        <div data-mesh-id="Containerck70inlineContent-gridContainer"
                                             data-testid="mesh-container-content">
                                            <section id="comp-l6y7k0pe" tabIndex="-1"
                                                     className="Oqnisf comp-l6y7k0pe wixui-section">
                                                <div id="bgLayers_comp-l6y7k0pe" data-hook="bgLayers" className="MW5IWV">
                                                    <div data-testid="colorUnderlay" className="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0pe" className="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0peinlineContent" data-testid="inline-content">
                                                    <div data-mesh-id="comp-l6y7k0peinlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <div id="comp-kzrgxiy6"
                                                             className="BaOVQ8 tz5f0K comp-kzrgxiy6 wixui-rich-text"
                                                             data-testid="richTextElement">
                                                            <h2
                                                                className="font_2 wixui-rich-text__text"
                                                                style={{fontSize: '20px', textAlign: 'center'}}>
                                                                <span
                                                                    style={{letterSpacing: '0.2em'}}
                                                                    className="wixui-rich-text__text">
                                                                    <span
                                                                        style={{fontSize: '20px'}}
                                                                        className="wixui-rich-text__text">
                                                                        <span
                                                                            style={{fontFamily: 'avenir-lt-w01_85-heavy1475544, avenir-lt-w05_85-heavy,sans-serif'}}
                                                                            className="wixui-rich-text__text">
                                                                            <span id="about-ghjgjhg" style={{color: '#292929'}}
                                                                              className="wixui-rich-text__text">ABOUT
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </h2>
                                                    </div>
                                                        <div id="i0rskj96" className="KcpHeO tz5f0K i0rskj96 wixui-rich-text"
                                                             data-testid="richTextElement"><p
                                                            className="font_7 wixui-rich-text__text"
                                                            style={{ textAlign : 'center',  fontSize: '16px'}}><span
                                                            style={{ fontFamily : 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                                            className="wixui-rich-text__text">Catherine Agor is a set and background designer based in California, USA.</span>
                                                        </p>

                                                            <p className="font_7 wixui-rich-text__text"
                                                               style={{ textAlign : 'center',  fontSize  : '16px'}}><span
                                                                style={{ fontFamily : 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                                                className="wixui-rich-text__text">She has a strong interest in architecture and interiors 🏰.</span>
                                                            </p>

                                                            <p className="font_7 wixui-rich-text__text"
                                                               style={{ textAlign : 'center',  fontSize: '16px'}}><span
                                                                style={{ fontFamily : 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                                                className="wixui-rich-text__text"><span
                                                                className="wixGuard wixui-rich-text__text">​</span></span></p>

                                                            <p className="font_7 wixui-rich-text__text"
                                                               style={{ textAlign : 'center',  fontSize : '16px'}}><span
                                                                style={{ fontFamily : 'futura-lt-w01-light,futura-lt-w05-light,sans-serif' }}
                                                                className="wixui-rich-text__text"><span
                                                                className="wixGuard wixui-rich-text__text">​</span></span></p></div>
                                                        <div id="comp-kzrjn4oe" className="comp-kzrjn4oe WzbAF8">
                                                            <ul className="mpGTIt" aria-label="Social Bar">
                                                            <li id="dataItem-kzrjn4q21-comp-kzrjn4oe" className="O6KwRn"><a
                                                                data-testid="linkElement" href="mailto:agor.cat@gmail.com"
                                                                title="agor.cat@gmail.com"
                                                                target="_blank" rel="noreferrer noopener" className="oRtuWN">
                                                                <div id="img_1_comp-kzrjn4oe" className="HlRz5e YaS0jR"
                                                                     data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrjn4q21-comp-kzrjn4oe&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:200,&quot;height&quot;:200,&quot;uri&quot;:&quot;e1aa082f7c0747168d9cf43e77046142.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                     data-bg-effect-name="" data-has-ssr-src=""
                                                                     style={{ maxWidth : ' max(200px, 100%)' }}
                                                                     data-src="https://static.wixstatic.com/media/888d49_66447eef77f04ad394bb391e4318bd47~mv2.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_66447eef77f04ad394bb391e4318bd47~mv2.png">
                                                                    <img alt="Instagram" fetchpriority="high"
                                                                         src="https://static.wixstatic.com/media/888d49_66447eef77f04ad394bb391e4318bd47~mv2.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_66447eef77f04ad394bb391e4318bd47~mv2.png"
                                                                         style={{ width : ' 39px',  height: '39px', objectFit: 'cover' }} />
                                                                </div>
                                                            </a></li>
                                                                <li id="dataItem-kzrkl8y9-comp-kzrjn4oe" className="O6KwRn">
                                                                    <a
                                                                    data-testid="linkElement"
                                                                    href="https://www.artstation.com/agorcat/" target="_blank"
                                                                    title="artstation.com/agorcat/"
                                                                    rel="noreferrer noopener" className="oRtuWN">
                                                                    <div id="img_0_comp-kzrjn4oe" className="HlRz5e YaS0jR"
                                                                               data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrkl8y9-comp-kzrjn4oe&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:94,&quot;height&quot;:94,&quot;uri&quot;:&quot;888d49_beb33fda134e43f29073ba02fde35cb2~mv2.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                               data-bg-effect-name="" data-has-ssr-src=""
                                                                               style={{ maxWidth : ' max(94px, 100%)' }}
                                                                               data-src="https://static.wixstatic.com/media/888d49_beb33fda134e43f29073ba02fde35cb2~mv2.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_beb33fda134e43f29073ba02fde35cb2~mv2.png">
                                                                        <img alt="ArtStation-logomark-white1" fetchpriority="high"
                                                                             src="https://static.wixstatic.com/media/888d49_beb33fda134e43f29073ba02fde35cb2~mv2.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/888d49_beb33fda134e43f29073ba02fde35cb2~mv2.png"
                                                                             style={{ width : ' 39px', height : '39px', objectFit: 'cover'}} />
                                                                    </div>
                                                                </a></li>
                                                                <li id="dataItem-kzrjn4q21-comp-kzrjn4oe" className="O6KwRn">
                                                                    <a title="instagram.com/agor.cat"
                                                                    data-testid="linkElement" href="https://www.instagram.com/agor.cat"
                                                                    target="_blank" rel="noreferrer noopener" className="oRtuWN">
                                                                    <div id="img_1_comp-kzrjn4oe" className="HlRz5e YaS0jR"
                                                                               data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrjn4q21-comp-kzrjn4oe&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:200,&quot;height&quot;:200,&quot;uri&quot;:&quot;e1aa082f7c0747168d9cf43e77046142.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                               data-bg-effect-name="" data-has-ssr-src=""
                                                                               style={{ maxWidth : ' max(200px, 100%)' }}
                                                                               data-src="https://static.wixstatic.com/media/e1aa082f7c0747168d9cf43e77046142.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e1aa082f7c0747168d9cf43e77046142.png">
                                                                        <img alt="Instagram" fetchpriority="high"
                                                                             src="https://static.wixstatic.com/media/e1aa082f7c0747168d9cf43e77046142.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e1aa082f7c0747168d9cf43e77046142.png"
                                                                             style={{ width : ' 39px',  height: '39px', objectFit: 'cover' }} />
                                                                    </div>
                                                                </a></li>
                                                                <li id="dataItem-kzrjn4q72-comp-kzrjn4oe" className="O6KwRn"><a
                                                                    data-testid="linkElement"
                                                                    title="YouTube Channel"
                                                                    href="https://www.youtube.com/channel/UCZhJmYyC6CC1gKrCx-l0ukQ"
                                                                    target="_blank" rel="noreferrer noopener" className="oRtuWN">
                                                                    <div id="img_2_comp-kzrjn4oe" className="HlRz5e YaS0jR"
                                                                               data-image-info="{&quot;containerId&quot;:&quot;dataItem-kzrjn4q72-comp-kzrjn4oe&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;imageData&quot;:{&quot;width&quot;:200,&quot;height&quot;:200,&quot;uri&quot;:&quot;45bce1d726f64f1999c49feae57f6298.png&quot;,&quot;name&quot;:&quot;&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                               data-bg-effect-name="" data-has-ssr-src=""
                                                                               style={{ maxWidth : ' max(200px, 100%)' }}
                                                                               data-src="https://static.wixstatic.com/media/45bce1d726f64f1999c49feae57f6298.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/45bce1d726f64f1999c49feae57f6298.png">
                                                                        <img alt="YouTube" fetchpriority="high"
                                                                             src="https://static.wixstatic.com/media/45bce1d726f64f1999c49feae57f6298.png/v1/fill/w_78,h_78,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/45bce1d726f64f1999c49feae57f6298.png"
                                                                             style={{ width : ' 39px', height : '39px', objectFit: 'cover'}} />
                                                                    </div>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                        <div id="comp-kzrjaoyc" className="comp-kzrjaoyc SPY_vo">
                                                            <div data-mesh-id="comp-kzrjaoycinlineContent"
                                                                 data-testid="inline-content" className="">
                                                                <div data-mesh-id="comp-kzrjaoycinlineContent-gridContainer"
                                                                     data-testid="mesh-container-content">
                                                                    <div id="comp-kzrg2bge"
                                                                         className="BaOVQ8 tz5f0K comp-kzrg2bge wixui-rich-text"
                                                                         data-testid="richTextElement"><h2
                                                                        className="font_2 wixui-rich-text__text"
                                                                        style={{ fontSize : '20px',  textAlign : 'center'}}><span
                                                                        style={{ letterSpacing : '0.2em' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ fontSize : '20px' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ fontFamily : 'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif' }}
                                                                        className="wixui-rich-text__text">
                                                                        <span
                                                                        style={{ color : '#292929' , fontWeight: 500}}
                                                                        className="wixui-rich-text__text">SKILLS</span></span></span></span>
                                                                    </h2></div>
                                                                    <div id="comp-kzrgcdc4" className="comp-kzrgcdc4">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrn8bty__root wixui-button"
                                                                                data-testid="buttonContent"
                                                                                aria-label="3D modeling">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">3D modeling</span>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrgamai" className="comp-kzrgamai">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrn72nf__root wixui-button"
                                                                                data-testid="buttonContent"
                                                                                aria-label="environment design">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">environment design</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrg5vm3" className="comp-kzrg5vm3">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrn673k__root wixui-button"
                                                                                data-testid="buttonContent"
                                                                                aria-label="concept art">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">concept art</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrgdw8l" className="comp-kzrgdw8l">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrn6nka__root wixui-button"
                                                                                data-testid="buttonContent"
                                                                                aria-label="illustration">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">illustration</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrgk1lc" className="comp-kzrgk1lc">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrnh4ms__root wixui-button"
                                                                                data-testid="buttonContent" aria-label="lighting">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">lighting</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrgjni6" className="comp-kzrgjni6">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrnhbyh__root wixui-button"
                                                                                data-testid="buttonContent" aria-label="rendering">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">rendering</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrgkm2d" className="comp-kzrgkm2d">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrnhj7y__root wixui-button"
                                                                                data-testid="buttonContent"
                                                                                aria-label="digital painting">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">digital painting</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                    <div id="comp-kzrj69g4" className="comp-kzrj69g4">
                                                                        <button type="button"
                                                                                className="StylableButton2545352419__root style-kzrnhsdc__root wixui-button"
                                                                                data-testid="buttonContent"
                                                                                aria-label="architecture design">
                                                                            <div className="StylableButton2545352419__container">
                                                                                <span
                                                                                    className="StylableButton2545352419__label wixui-button__label"
                                                                                    data-testid="stylablebutton-label">architecture design</span><span
                                                                                className="StylableButton2545352419__icon wixui-button__icon"
                                                                                aria-hidden="true"
                                                                                data-testid="stylablebutton-icon"><div><svg
                                                                                data-bbox="13.05 2.55 33.878 54.8"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 60 60">
                <g>
                    <path
                        d="M46.5 28.9L20.6 3c-.6-.6-1.6-.6-2.2 0l-4.8 4.8c-.6.6-.6 1.6 0 2.2l19.8 20-19.9 19.9c-.6.6-.6 1.6 0 2.2l4.8 4.8c.6.6 1.6.6 2.2 0l21-21 4.8-4.8c.8-.6.8-1.6.2-2.2z"></path>
                </g>
            </svg>
            </div></span></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-mesh-id="comp-l6y7k0peinlineContent-wedge-5"></div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section id="comp-l6y7k0pe1" tabIndex="-1"
                                                     className="Oqnisf comp-l6y7k0pe1 wixui-section">
                                                <div id="bgLayers_comp-l6y7k0pe1" data-hook="bgLayers" className="MW5IWV">
                                                    <div data-testid="colorUnderlay" className="LWbAav Kv1aVt"></div>
                                                    <div id="bgMedia_comp-l6y7k0pe1" className="VgO9Yg"></div>
                                                </div>
                                                <div data-mesh-id="comp-l6y7k0pe1inlineContent" data-testid="inline-content"
                                                     className="">
                                                    <div data-mesh-id="comp-l6y7k0pe1inlineContent-gridContainer"
                                                         data-testid="mesh-container-content">
                                                        <div id="comp-kzrfwxe4"
                                                             className="BaOVQ8 tz5f0K comp-kzrfwxe4 wixui-rich-text"
                                                             data-testid="richTextElement"><h2
                                                            className="font_2 wixui-rich-text__text"
                                                            style={{ fontSize : '20px',  textAlign : 'center'}}><span
                                                            style={{ letterSpacing : '0.2em' }} className="wixui-rich-text__text"><span
                                                            style={{ fontSize : '20px' }} className="wixui-rich-text__text"><span
                                                            style={{ fontFamily : 'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif' }}
                                                            className="wixui-rich-text__text"><span style={{ color : '#292929', fontWeight: 500 }}
                                                                                                    className="wixui-rich-text__text">SOFTWARE</span></span></span></span>
                                                        </h2></div>
                                                        <div id="comp-kzrjgps3" className="comp-kzrjgps3 SPY_vo">
                                                            <div data-mesh-id="comp-kzrjgps3inlineContent"
                                                                 data-testid="inline-content" className="">
                                                                <div data-mesh-id="comp-kzrjgps3inlineContent-gridContainer"
                                                                     data-testid="mesh-container-content">
                                                                    <div id="comp-kzrg184u"
                                                                         className="MazNVa comp-kzrg184u wixui-image">
                                                                        <div data-testid="linkElement" className="j7pOnl">
                                                                            <div id="img_comp-kzrg184u"
                                                                                       className="HlRz5e BI8PVQ"
                                                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kzrg184u&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:60,&quot;targetHeight&quot;:60,&quot;isLQIP&quot;:false,&quot;imageData&quot;:{&quot;width&quot;:60,&quot;height&quot;:60,&quot;uri&quot;:&quot;888d49_e38a9f56944e4e65956d643ca9fced18~mv2.png&quot;,&quot;name&quot;:&quot;blender_icon_1024x1024.png_1570478232.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                       data-bg-effect-name="" data-has-ssr-src=""
                                                                                       data-src="https://static.wixstatic.com/media/888d49_e38a9f56944e4e65956d643ca9fced18~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/blender_icon_1024x1024_png_1570478232.png">
                                                                                <img
                                                                                    src="https://static.wixstatic.com/media/888d49_e38a9f56944e4e65956d643ca9fced18~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/blender_icon_1024x1024_png_1570478232.png"
                                                                                    alt="blender_icon_1024x1024.png_1570478232.png"
                                                                                    width="60" height="60"
                                                                                    srcSet="https://static.wixstatic.com/media/888d49_e38a9f56944e4e65956d643ca9fced18~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/blender_icon_1024x1024_png_1570478232.png"
                                                                                    fetchpriority="high"
                                                                                    style={{ width : ' 60px', height : '60px', objectFit: 'cover'}} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="comp-kzrgvcpy"
                                                                         className="BaOVQ8 tz5f0K comp-kzrgvcpy wixui-rich-text"
                                                                         data-testid="richTextElement"><h2
                                                                        className="font_2 wixui-rich-text__text"
                                                                        style={{ fontSize : '16px' }}><span style={{ letterSpacing : '0em' }}
                                                                                                      className="wixui-rich-text__text"><span
                                                                        style={{ fontFamily : 'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ fontSize : '16px' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ color : '#292929' }}
                                                                        className="wixui-rich-text__text">Blender</span></span></span></span>
                                                                    </h2></div>
                                                                    <div id="comp-kzrg1856"
                                                                         className="MazNVa comp-kzrg1856 wixui-image">
                                                                        <div data-testid="linkElement" className="j7pOnl">
                                                                            <div id="img_comp-kzrg1856"
                                                                                       className="HlRz5e BI8PVQ"
                                                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kzrg1856&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:60,&quot;targetHeight&quot;:60,&quot;isLQIP&quot;:false,&quot;imageData&quot;:{&quot;width&quot;:60,&quot;height&quot;:60,&quot;uri&quot;:&quot;888d49_10d1923f2c37463d9f935d46a8535bd6~mv2.png&quot;,&quot;name&quot;:&quot;Photoshop.png_1608144074.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                       data-bg-effect-name="" data-has-ssr-src=""
                                                                                       data-src="https://static.wixstatic.com/media/888d49_10d1923f2c37463d9f935d46a8535bd6~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/Photoshop_png_1608144074.png">
                                                                                {/*<img*/}
                                                                                {/*    src="https://static.wixstatic.com/media/888d49_10d1923f2c37463d9f935d46a8535bd6~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/Photoshop_png_1608144074.png"*/}
                                                                                {/*    alt="Photoshop.png_1608144074.png" width="60"*/}
                                                                                {/*    height="60"*/}
                                                                                {/*    srcSet="https://static.wixstatic.com/media/888d49_10d1923f2c37463d9f935d46a8535bd6~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/Photoshop_png_1608144074.png"*/}
                                                                                {/*    fetchpriority="high"*/}
                                                                                {/*    style={{ width : ' 60px', height : '60px', objectFit: 'cover'}} />*/}
                                                                                <img
                                                                                    src="https://static.wixstatic.com/media/888d49_10d1923f2c37463d9f935d46a8535bd6~mv2.png/v1/fill/w_60,h_60,al_c,q_85,enc_auto/Photoshop_png_1608144074.png"
                                                                                    alt="Photoshop.png_1608144074.png"
                                                                                    style={{ width : ' 60px', height : '60px', objectFit: 'cover'}}
                                                                                    width="60" height="60"
                                                                                    srcSet="https://static.wixstatic.com/media/888d49_10d1923f2c37463d9f935d46a8535bd6~mv2.png/v1/fill/w_60,h_60,al_c,q_85,enc_auto/Photoshop_png_1608144074.png"
                                                                                    fetchpriority="high" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="comp-kzrgvqj1"
                                                                         className="BaOVQ8 tz5f0K comp-kzrgvqj1 wixui-rich-text"
                                                                         data-testid="richTextElement"><h2
                                                                        className="font_2 wixui-rich-text__text"
                                                                        style={{ fontSize : '16px' }}><span style={{ letterSpacing : '0em' }}
                                                                                                      className="wixui-rich-text__text"><span
                                                                        style={{ fontFamily : 'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ fontSize : '16px' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ color : '#292929' }}
                                                                        className="wixui-rich-text__text">Photoshop</span></span></span></span>
                                                                    </h2></div>
                                                                    <div id="comp-kzrg1853"
                                                                         className="MazNVa comp-kzrg1853 wixui-image">
                                                                        <div data-testid="linkElement" className="j7pOnl">
                                                                            <div id="img_comp-kzrg1853"
                                                                                       className="HlRz5e BI8PVQ"
                                                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kzrg1853&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:60,&quot;targetHeight&quot;:60,&quot;isLQIP&quot;:false,&quot;imageData&quot;:{&quot;width&quot;:60,&quot;height&quot;:60,&quot;uri&quot;:&quot;888d49_1756eb3294bf46428aed8b07f660d307~mv2.png&quot;,&quot;name&quot;:&quot;sketchup.png_1424819106.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                       data-bg-effect-name="" data-has-ssr-src=""
                                                                                       data-src="https://static.wixstatic.com/media/888d49_1756eb3294bf46428aed8b07f660d307~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/sketchup_png_1424819106.png">
                                                                                <img
                                                                                    src="https://static.wixstatic.com/media/888d49_1756eb3294bf46428aed8b07f660d307~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/sketchup_png_1424819106.png"
                                                                                    alt="sketchup.png_1424819106.png" width="60"
                                                                                    height="60"
                                                                                    srcSet="https://static.wixstatic.com/media/888d49_1756eb3294bf46428aed8b07f660d307~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/sketchup_png_1424819106.png"
                                                                                    fetchpriority="high"
                                                                                    style={{ width : ' 60px', height : '60px', objectFit: 'cover'}} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="comp-kzrgw2ry"
                                                                         className="BaOVQ8 tz5f0K comp-kzrgw2ry wixui-rich-text"
                                                                         data-testid="richTextElement"><h2
                                                                        className="font_2 wixui-rich-text__text"
                                                                        style={{ fontSize : '16px' }}><span style={{ letterSpacing : '0em' }}
                                                                                                      className="wixui-rich-text__text"><span
                                                                        style={{ fontFamily : 'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ fontSize : '16px' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ color : '#292929' }}
                                                                        className="wixui-rich-text__text">SketchUp</span></span></span></span>
                                                                    </h2></div>
                                                                    <div id="comp-kzrg1858"
                                                                         className="MazNVa comp-kzrg1858 wixui-image">
                                                                        <div data-testid="linkElement" className="j7pOnl">
                                                                            <div id="img_comp-kzrg1858"
                                                                                       className="HlRz5e BI8PVQ"
                                                                                       data-image-info="{&quot;containerId&quot;:&quot;comp-kzrg1858&quot;,&quot;displayMode&quot;:&quot;fill&quot;,&quot;targetWidth&quot;:60,&quot;targetHeight&quot;:60,&quot;isLQIP&quot;:false,&quot;imageData&quot;:{&quot;width&quot;:60,&quot;height&quot;:60,&quot;uri&quot;:&quot;888d49_b078dc2889e944cea803e7147af674be~mv2.png&quot;,&quot;name&quot;:&quot;DAZ_Studio.png_1424684345.png&quot;,&quot;displayMode&quot;:&quot;fill&quot;}}"
                                                                                       data-bg-effect-name="" data-has-ssr-src=""
                                                                                       data-src="https://static.wixstatic.com/media/888d49_b078dc2889e944cea803e7147af674be~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/DAZ_Studio_png_1424684345.png">
                                                                                <img
                                                                                    src="https://static.wixstatic.com/media/888d49_b078dc2889e944cea803e7147af674be~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/DAZ_Studio_png_1424684345.png"
                                                                                    alt="DAZ_Studio.png_1424684345.png" width="60"
                                                                                    height="60"
                                                                                    srcSet="https://static.wixstatic.com/media/888d49_b078dc2889e944cea803e7147af674be~mv2.png/v1/fill/w_84,h_84,al_c,lg_1,q_85,enc_auto/DAZ_Studio_png_1424684345.png"
                                                                                    fetchpriority="high"
                                                                                    style={{ width : ' 60px', height : '60px', objectFit: 'cover'}} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="comp-kzrgwlxb"
                                                                         className="BaOVQ8 tz5f0K comp-kzrgwlxb wixui-rich-text"
                                                                         data-testid="richTextElement"><h2
                                                                        className="font_2 wixui-rich-text__text"
                                                                        style={{ fontSize : '16px' }}><span style={{ letterSpacing : '0em' }}
                                                                                                      className="wixui-rich-text__text"><span
                                                                        style={{ fontFamily : 'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ fontSize : '16px' }}
                                                                        className="wixui-rich-text__text"><span
                                                                        style={{ color : '#292929' }} className="wixui-rich-text__text">Daz Studio</span></span></span></span>
                                                                    </h2></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
    )
    }
}